import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogForm from "../components/blog-form"
import BlogPageDetails from "../components/blog-page-details"
// import { Helmet } from 'react-helmet'
import Moment from "react-moment"
import { Remarkable } from "remarkable"
import RemarkableReactRender from "remarkable-react"
import GraphImg from "graphcms-image"

const md = new Remarkable()
md.renderer = new RemarkableReactRender()

export const query = graphql`
  query BlogTemplate($id: ID!) {
    gcms {
      blogPost(where: { id: $id }, stage: PUBLISHED) {
        title
        slug
        publicationDate
        blogAuthor {
          ... on GraphCMS_Author {
            profilePicture {
              url
            }
            name
            slug
          }
        }
        image: featuredImage {
          handle
          width
          height
        }
        content
        blogCategory {
          categoryName
          slug
        }
      }
    }
  }
`

const BlogPage = ({ data }) => {
  const { blogPost } = data.gcms
  const blogDetails = {
    title: blogPost.title,
    publicationDate: blogPost.publicationDate,
    featuredImage: blogPost.image,
    postSlug: blogPost.slug,
    authorImage: "",
    authorName: "",
    authorFirstName: "",
    authorLastName: "",
    category: "",
    content: blogPost.content,
  }

  if (blogPost.blogAuthor !== null) {
    blogDetails.authorImage = blogPost.blogAuthor.profilePicture.url
  }

  if (blogPost.blogAuthor !== null) {
    blogDetails.authorName = blogPost.blogAuthor.name
    let splitName = blogPost.blogAuthor.name.split(" ")
    blogDetails.authorFirstName = splitName[0]
    blogDetails.authorLastName = splitName[1]
  }

  if (blogPost.blogCategory != null) {
    blogDetails.category = blogPost.blogCategory.categoryName
  }

  return (
    <Layout isHeaderTransparent={true}>
      <SEO
        title={blogDetails.title + " | The Billsby Blog"}
        description={
          "Learn about " +
          blogDetails.title +
          "and other updates on subscription billing and recurring payments."
        }
        url={
          "https://www.billsby.com/" +
          blogDetails.publicationDate +
          "/" +
          blogDetails.postSlug
        }
        type={"article"}
        articleDetails={{
          publishedTime: blogDetails.publicationDate,
          authorFirstName:
            "profile:first_name - " + blogDetails.authorFirstName,
          authorLastName: "profile:last_name - " + blogDetails.authorLastName,
          section: blogDetails.category,
          image: blogDetails.featuredImage,
        }}
      />

      <div
        className="section-blog-banner"
        // style={{backgroundImage: `url(${blogDetails.featuredImage})` }}
      >
        <GraphImg image={blogDetails.featuredImage} maxWidth={1920} />

        <div className="container">
          <h1 className="heading-1">{blogDetails.title}</h1>
          <p className="subheading-1">
            <Moment format="MMMM DD YYYY">{blogDetails.publicationDate}</Moment>
          </p>
          <BlogForm buttonLabel="Get more posts like this one" />
        </div>
      </div>

      {blogPost.blogAuthor !== null && blogPost.blogCategory != null && (
        <BlogPageDetails
          authorSlug={blogPost.blogAuthor.slug}
          authorImage={blogPost.blogAuthor.profilePicture.url}
          authorName={blogPost.blogAuthor.name}
          categorySlug={blogPost.blogCategory.slug}
          category={blogPost.blogCategory.categoryName}
        />
      )}

      <div className="section-main-content">
        <div className="container container-sm">
          <div className="content">{md.render(blogDetails.content)}</div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage
